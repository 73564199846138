<template>
    <div class="admin-settings-trnaslations">
        <b-card class="cms-pages-admin" :title="$t('Subscriptions')">
            <b-row>
                <b-col cols="12">
                    <good-data-table ref="subscriptionTable" :columns="fields" :no-serial="true"
                        :total-column="'response.responseData.total'" :data-column="'response.responseData.data'"
                        :api-endpoint="'/subscription/subscriptions-paginate-provider'" @add-new-info="toggelModal = true" />
                </b-col>
            </b-row>
        </b-card>
        <b-modal v-model="paymentModal" centered title="Payment Details">
            <template v-if="!isEmpty(paymentData)">
                <b-row class="mt-1" v-for="(value, key) in paymentData" :key="`pd_${key}`">
                    <b-col cols="6" md="4" class="text-right text-uppercase font-weight-bold pr-0 mr-0 text-muted">{{ key
                    }}: </b-col>
                    <b-col cols="6" md="8" class="text-left pl-0 ml-0">{{ value }}</b-col>
                </b-row>
            </template>
        </b-modal>
        <b-modal v-model="subscriptionHistoryModal" centered title="Subscription Histories" size="xl">
            <ViewSubscriptionHistory :subscriptionHistories="subscriptionHistories" />
        </b-modal>
    </div>
</template>
    
<script>
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { mapActions, mapGetters } from 'vuex'
import { isEmpty, dateTimeFormat } from '@/utilities'
import ViewSubscriptionHistory from '@/views/admin/subscription-package/ViewSubscriptionHistory.vue'

export default {
    name: "ProviderSubscribers",
    components: {
        GoodDataTable,
        ViewSubscriptionHistory
    },
    data: () => ({
        paymentModal: false,
        subscriptionHistoryModal: false,
        paymentData: {},
        subscriptionHistories: [],
    }),
    computed: {
        ...mapGetters('subscriptionPlan', [
            'isSubscriptionLoaded',
        ]),
        fields: {
            get() {
                return [
                    {
                        field: 'user',
                        label: 'Account Name',
                        sortable: false,
                    },
                    {
                        field: 'plan_name',
                        label: 'Plan Name',
                        sortable: true,
                    },
                    {
                        field: 'price',
                        label: 'Plan Amount',
                    },
                    {
                        field: 'start_date',
                        label: 'Start Date',
                    },
                    {
                        field: 'end_date',
                        label: 'End Date',
                    },
                    {
                        field: 'status',
                        label: 'Status',
                        sortable: true,
                    },
                    {
                            label: 'Usage',
                            field: 'usage',
                            sortable: false,
                            useHtml: true,
                            renderer: props => {
                                let usage;
                                if(!isEmpty(props.usage) && typeof props.usage == 'object'){
                                    usage = Object.values(props.usage)
                                }
                                if(!isEmpty(usage) && Array.isArray(usage)){
                                    usage = usage.map(i => `<div>${i}</div>`)
                                    return usage.join('');
                                }
                                if(typeof props.usage == 'string'){
                                    return props.usage
                                }
                                return '--'
                            },
                    },
                    {
                        field: 'exceed_orders',
                        label: 'Exceed Orders',
                        sortable: false,
                        useHtml: true,
                        renderer: props => props.exceed_orders
                    },
                    {
                        field: 'total_orders',
                        label: 'Total Orders',
                        sortable: false,
                        useHtml: true,
                        renderer: props => props.total_orders
                    },
                    {
                        field: 'created_at',
                        label: 'Created On',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => isEmpty(props.created_at) ? 'N/A' : dateTimeFormat(props.created_at, 'DD MMM, YYYY'),
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        sortable: false,
                        type: 'dropdown',
                        buttons: [
                            {
                                icon: 'View Payment Details',
                                title: 'View Payment Details',
                                image: this.$helpers.getIcons('view'),
                                action: props => {
                                    this.paymentData = props.payment_details
                                    this.paymentModal = true
                                },
                            },
                            {
                                icon: 'View Subscription Histories',
                                title: 'View Subscription Histories',
                                image: this.$helpers.getIcons('schedule.png'),
                                action: props => {
                                    this.subscriptionHistories = props.subscription_histories
                                    this.subscriptionHistoryModal = true
                                },
                            },
                            {
                                icon: 'Approve Cancellation',
                                title: 'Approve Cancellation',
                                image: this.$helpers.getIcons('confirm.png'),
                                canRender: props => props.subscription_status == 4,
                                action: props => {
                                    this.$swal({
                                        title: this.$t('Are you sure you want to approve the cancellation request of this subcription?'),
                                        iconHtml: this.$helpers.swalIcon('confirm.png'),
                                        showCancelButton: true,
                                        confirmButtonText: this.$t('Yes'),
                                        cancelButtonText: this.$t('No'),
                                        customClass: {
                                            confirmButton: 'btn btn-success',
                                            cancelButton: 'btn btn-danger ml-1',
                                            icon: 'border-0',
                                        },
                                        buttonsStyling: false,
                                    }).then(result => {
                                        if (result.value) {
                                            this.deleteItem(props)
                                        }
                                    })
                                },
                            },
                        ],
                    },
                ]
            }
        },
    },
    methods: {
        ...mapActions('subscriptionService', [
            'cancelSubscription',
        ]),
        ...mapActions('subscriptionPlan', [
            'getSubscriptionPackages',
        ]),
        isEmpty,
        async deleteItem(i) {
            await this.cancelSubscription(i.id)
            this.$refs.subscriptionTable.loadItems()
        },
    },
    mounted(){
        if (!this.isSubscriptionLoaded) {
            this.getSubscriptionPackages()
        }
    }
}
</script>
    